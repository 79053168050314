<template>
  <div>
    <page-title
      title="Admins"
      subtitle="Manage all the admins who have access to this organization"
      action-name="Add new"
      @click="onAddAdmin"
    ></page-title>

    <el-row :gutter="20" class="admins-containers" v-loading="loading">
      <el-col :sm="12" :md="8" v-for="(admin, key) in organizationData.admins" class="admins-item">
        <user-card
          :image="getUserPhoto(key, 128)"
          :data="{first_name: admin.display_name.split(' ')[0], last_name: admin.display_name.split(' ')[1]}"
          :user-id="key"
          :subtext="(admin.read && admin.write) ? 'Can read & write' : (admin.read ? 'Can read only' : 'Can write only')"
          @clicked="onAdminClick"
          :action="user_options"
        ></user-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import PageTitle from '@/components/PageTitle';
import UserCard from '@/components/UserCard';
import lodash from 'lodash';
import firebase from '@/firebase';

export default {
  components: { PageTitle, UserCard },

  data() {
    return {
      admins: [],
      user_options: [
        { label: 'Delete admin', value: '--', icon: 'el-icon-delete' },
        { label: 'Set read only', value: 'r-', icon: 'el-icon-reading' },
        // { label: 'Set write only', value: '-w', icon: 'el-icon-edit' },
        { label: 'Set read & write', value: 'rw', icon: 'el-icon-edit-outline' }
      ],
      loading: false
    };
  },

  methods: {
    onAddAdmin() {
      this.$prompt("What's the email of the new admin?", 'Add a Admin', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        inputPattern: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
        inputErrorMessage: 'Invalid Email'
      })
        .then(async ({ value }) => {
          // Set laoding
          this.loading = true;

          this.SharoAPI({
            action: 'addOrDeleteAdminFromOrganization',
            data: { email: value, permissions: { write: false, read: true } }
          }).then(result => {
            if (result.data.success) {
              this.$notify({
                message: result.data.message,
                type: 'success',
                title: 'Success'
              });
            } else {
              this.$notify({
                message: result.data.error_message,
                type: 'error',
                title: 'Error'
              });
            }
          });
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },

    onAdminClick(id, action) {
      let permissions = {
        write: action && action.includes('w'),
        read: action && action.includes('r')
      };

      // Set laoding
      this.loading = true;

      this.SharoAPI({
        action: 'addOrDeleteAdminFromOrganization',
        data: { _id: id, permissions: permissions }
      })
        .then(result => {
          console.log(result);
          if (result.data.success) {
            this.$notify({
              message: result.data.message,
              type: 'success',
              title: 'Success'
            });
          } else {
            this.$notify({
              message: result.data.error_message,
              type: 'error',
              title: 'Error'
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },

  computed: {
    organizationData() {
      return this.$store.getters.organization.data || {};
    }
  }
};
</script>

<style lang="scss" scoped>
.el-col {
  margin-bottom: 20px !important;
}
</style>