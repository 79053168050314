<template>
  <div class="container">
    <page-title title="Shuttle Buses" subtitle="All the shuttles can be managed from here"></page-title>
  </div>
</template>

<script>
import PageTitle from '@/components/PageTitle'
import AnalyticsOverview from '@/components/AnalyticsOverview'

export default {
  components: {
    PageTitle,
    AnalyticsOverview
  },

  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
