<template>
  <div>
    <page-title title="Configuration"></page-title>
  
    <el-row :gutter="50">
      <el-col :sm="12" :lg="8">
        <div class="settings-pane">
          <div class="settings-text">
            <span>Allow carpooling</span>
            <span>Allow people to use carpooling functionality</span>
          </div>
          <div class="settings-switch">
            <el-switch
              v-model="val"
              active-color="#ffa140"
              >
            </el-switch>
          </div>
        </div>

        <div class="settings-pane">
          <div class="settings-text">
            <span>Allow active travel</span>
            <span>Allow people to log walking, cycling and other active travel options</span>
          </div>
          <div class="settings-switch">
            <el-switch
              v-model="val"
              active-color="#ffa140"
              >
            </el-switch>
          </div>
        </div>

        <div class="settings-pane">
          <div class="settings-text">
            <span>Allow public transport</span>
            <span>Allow people to log public transport options</span>
          </div>
          <div class="settings-switch">
            <el-switch
              v-model="val"
              active-color="#ffa140"
              >
            </el-switch>
          </div>
        </div>

        <br>
        <el-divider>Points</el-divider>

        <div class="settings-pane">
          <div class="settings-text">
            <span>Allow speding points</span>
            <span>Allow people to spend their points on rewards</span>
          </div>
          <div class="settings-switch">
            <el-switch
              v-model="val"
              active-color="#ffa140"
              >
            </el-switch>
          </div>
        </div>
        
      </el-col>

      <el-col :sm="12" :lg="8" class="settings-pane">
        <div class="settings-text">
          <span>Allow carpooling</span>
          <span>Allow people to use carpooling and to find each other</span>
        </div>
        <div class="settings-switch">
          <el-switch
            v-model="val"
            active-color="#ffa140"
            >
          </el-switch>
        </div>
        
      </el-col>

      
    </el-row>
  </div>
</template>

<script>
import PageTitle from '@/components/PageTitle';

export default {
  components: { PageTitle },
  data() {
    return {
      val: true
    }
  },

  methods: {
    save() {
      console.log('saving')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

  .settings-pane {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 15px;

    .settings-text {

      span:nth-child(1) {
        display: block;
      }

      span:nth-child(2) {
        display: block;
        font-size: 13px;
        color: $info-color;
      }
    }

    .settings-switch {

    }
  }
</style>