<template>
  <div class="container">
    <page-title title="Wellness Analytics" subtitle="All your HR metrics in one place"></page-title>

    <el-row :gutter="20">
      <el-col :xs="24" :sm="8">
        <card>
          <analytics-overview
            color="bisque"
            text="happiness score"
            value="4.2"
            :icon="['far', 'smile']"
          ></analytics-overview>
        </card>
      </el-col>

      <el-col :xs="24" :sm="8">
        <card>
          <analytics-overview
            color="bisque"
            text="active users"
            value="72%"
            :icon="['fas', 'chart-bar']"
          ></analytics-overview>
        </card>
      </el-col>

      <el-col :xs="24" :sm="8">
        <card>
          <analytics-overview
            color="bisque"
            text="commuting time"
            value="92 min"
            :icon="['fas', 'route']"
          ></analytics-overview>
        </card>
      </el-col>

      <el-col :xs="24" :sm="13">
        <card
          title="Happiness by Gender & Age"
          :title-style="{ 'margin-bottom': '0px'}"
          :body-style="{'padding-bottom': '5px'}"
        >
          <apexchart width="100%" height="260px" type="bar" :options="options" :series="series"></apexchart>
        </card>
      </el-col>

      <el-col :xs="24" :sm="11">
        <card
          title="Happiness by Commute Mode"
          :title-style="{ 'margin-bottom': '0px'}"
          :body-style="{'padding-bottom': '5px'}"
        >
          <apexchart
            width="100%"
            height="260px"
            type="bar"
            :options="optionsCommute"
            :series="seriesCommute"
          ></apexchart>
        </card>
      </el-col>

      <el-col :xs="24" :sm="24">
        <card :action="[]" title="Happiness by Location">
          <div style="height: 350px" ref="google_maps"></div>
        </card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import PageTitle from '@/components/PageTitle'
import Card from '@/components/Card'
import AnalyticsOverview from '@/components/AnalyticsOverview'
import GoogleMapsInit from '@/utils/gmaps'

export default {
  components: {
    PageTitle,
    AnalyticsOverview,
    Card
  },

  data() {
    return {
      series: [
        {
          name: 'Male',
          data: [2, 55, 57, 56, 61, 58, 3]
        },
        {
          name: 'Female',
          data: [1, 44, 65, 49, 63, 49, 6]
        }
      ],
      options: {
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          }
        },
        chart: {
          toolbar: {
            show: false
          }
        },
        dataLabels: {
          enabled: false
        },

        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },

        xaxis: {
          show: true,
          categories: [
            '0-18',
            '19-25',
            '26-35',
            '36-45',
            '46-55',
            '56-65',
            '66-100'
          ]
        },
        yaxis: {
          show: true
        },
        fill: {
          opacity: 1
        }
      },
      seriesCommute: [
        {
          name: 'Happiness Level',
          data: [3.2, 2, 4.2, 4, 4.7, 4.3]
        }
      ],
      optionsCommute: {
        plotOptions: {
          bar: {
            horizontal: true,
            columnWidth: '55%',
            endingShape: 'rounded'
          }
        },
        chart: {
          toolbar: {
            show: false
          }
        },
        dataLabels: {
          enabled: false
        },

        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },

        xaxis: {
          show: true,
          categories: [
            'Public Transport',
            'Drive',
            'Carpool',
            'Shuttle Bus',
            'Walk',
            'Cycle'
          ]
        },
        yaxis: {
          show: true
        },
        fill: {
          opacity: 1
        }
      }
    }
  },

  mounted() {
    GoogleMapsInit().then(google => {
      new google.maps.Map(this.$refs.google_maps, {
        zoom: 10,
        center: { lat: 53.3198, lng: -6.4623 },
        // mapTypeId: 'satellite',
        disableDefaultUI: true
      })
    })
  },

  methods: {}
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

.card {
  margin-bottom: 20px;
}

.score-container {
  .overall-score {
    font-size: 100px;
    text-align: center;
    color: #444;
    margin-top: 50px;
  }
}
</style>
