<template>
  <div>
    <page-title title="Goals Settings"></page-title>
  </div>
</template>

<script>
import PageTitle from '@/components/PageTitle';

export default {
  components: { PageTitle }
}
</script>

<style lang="scss" scoped>

</style>