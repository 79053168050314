<template>
  <div id="app">
    <nav-bar></nav-bar>
    <div class="main-wrapper">
    <transition
        name="slide-fade"
        mode="out-in"
      >
      <router-view />
      </transition>
    </div>
  </div>
</template>
<script>
import NavBar from '@/components/NavBar'


export default {
  components: { NavBar },
  methods: {
    signedIn() {
      this.$store.state && this.$store.authentication && this.store.authentication.user;
    }
  }
}
</script>

