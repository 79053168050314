<template>
  <div class="container">
    <page-title title="Employees" subtitle="Manage all your employees in one place."></page-title>

    <!-- Search bar -->
    <div class="search-employees-container">
      <el-input v-model="search_input" placeholder="Search by email or name">
        <el-button slot="append" type="primary" icon="el-icon-search"></el-button>
      </el-input>
    </div>

    <!-- List of filtered_employees -->
    <el-row :gutter="20">
      <el-col v-for="user in filtered_employees.slice(0, this.max_employees_shown)" :sm="8">
        <user-card
          :image="getUserPhoto(user.id, 128)"
          :data="user.data"
          :user-id="user.id"
          @clicked="onUserClick"
          :action="[{label: 'Delete', value: 'delete', icon: 'el-icon-delete'}, {label: 'Block', value: 'block', icon: 'el-icon-circle-close'}]"
        ></user-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PageTitle from '@/components/PageTitle'
import UserCard from '@/components/UserCard'
import Card from '@/components/Card'
import firebase from '@/firebase'

export default {
  components: { PageTitle, UserCard, Card },
  data() {
    return {
      filtered_employees: [],
      search_input: '',
      search_timer: {
        delay: 500,
        timer: null
      },
      max_employees_shown: 12
    }
  },

  methods: {
    onUserClick(id, command) {
      if (command == 'delete') {
        this.$confirm(
          'Are you sure you want to delete this user? This action cannot be undone.',
          'Warning',
          {
            confirmButtonText: 'Yes',
            cancelButtonText: 'Cancel',
            type: 'warning'
          }
        )
          .then(() => {})
          .catch(() => {})
      } else if (command == 'block') {
        this.$confirm(
          'Are you sure you want to block this user? While blocked, they will not be able to use the platform.',
          'Warning',
          {
            confirmButtonText: 'Yes',
            cancelButtonText: 'Cancel',
            type: 'warning'
          }
        )
          .then(() => {})
          .catch(() => {})
      }
    },

    // On search input change, filter all employees
    applySearchFilter() {
      this.filtered_employees = [];

      // If input is empty, show all employees
      if (!this.search_input) {
        return this.filtered_employees = [...this.employees];
      }
      
      
      for (let index in this.employees) {
        let employee = this.employees[index];
        
        // Check if the input matches (first_name + last_name), first_name, last_name or email
         if ((employee.data.first_name + ' ' + employee.data.last_name).toLowerCase().startsWith(this.search_input.toLowerCase())
          || employee.data.first_name.toLowerCase().startsWith(this.search_input.toLowerCase())
          || employee.data.last_name.toLowerCase().startsWith(this.search_input.toLowerCase())
          || employee.data.email.toLowerCase().startsWith(this.search_input.toLowerCase())) {
           this.filtered_employees.push(employee);
         }
        
      }
    }
  },

  computed: {
    ...mapGetters(['employees'])
  },

  watch: {
    employees(val) {
      this.filtered_employees = [...val];
    },

    // On search input change, filter all employees
    search_input(val) {
      clearTimeout(this.search_timer.timer);
      this.search_timer.timer = setTimeout(_ => {
        this.applySearchFilter();
      }, this.search_timer.delay);
    }
  },

  mounted() {
    this.filtered_employees = [...this.employees];
  }
}
</script>


<style lang="scss" scoped>
.search-employees-container {
  margin-bottom: 20px;
}

.el-col {
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>