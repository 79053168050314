import Vue from 'vue';
import Router from 'vue-router';
import Head from 'vue-head';
import { isNil } from 'lodash';
import store from '@/store';

// Analytics
import AnalyticsHR from '@/views/Analytics/AnalyticsHR';
import AnalyticsSustainability from '@/views/Analytics/AnalyticsSustainability';
import AnalyticsExplore from '@/views/Analytics/AnalyticsExplore';
import AnalyticsAchievements from '@/views/Analytics/AnalyticsAchievements';

// Mobility
import Carpooling from '@/views/Mobility/Carpooling';
import ShuttleBusList from '@/views/Mobility/ShuttleBusList';

// Engagement
import Communication from '@/views/Engagement/Communication';
import Surveys from '@/views/Engagement/Surveys';
import Survey from '@/views/Engagement/Survey';
import Leaderboard from '@/views/Engagement/Leaderboard';

// More
import CandidateValidator from '@/views/More/CandidateValidator';
import KnowledgeBase from '@/views/More/KnowledgeBase';
import Employees from '@/views/More/Employees';
import EmployeeGroups from '@/views/More/EmployeeGroups';
import EmployeeGroup from '@/views/More/EmployeeGroup';

// Settings
import Settings from '@/views/Settings/SettingsDefault';
import SettingsAdmins from '@/views/Settings/SettingsAdmins';
import SettingsBilling from '@/views/Settings/SettingsBilling';
import SettingsRewards from '@/views/Settings/SettingsRewards';
import SettingsUsers from '@/views/Settings/SettingsUsers';
import SettingsGoals from '@/views/Settings/SettingsGoals';
import SettingsInformation from '@/views/Settings/SettingsInformation';
import SettingsConfig from '@/views/Settings/SettingsConfig';

Vue.use(Router);

/* If you don't know about VueHead, please refer to https://github.com/ktquez/vue-head */

Vue.use(Head, {
  complement: process.env.VUE_APP_TITLE
});

/* If you don't know about VueRouter, please refer to https://router.vuejs.org/ */

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      redirect: '/analytics/hr'
    },
    {
      path: '/analytics/hr',
      name: 'analytics_hr',
      component: AnalyticsHR,
      meta: {
        authRequired: true,
        guestRequired: false
      }
    },
    {
      path: '/analytics/sustainability',
      name: 'analytics_sustainability',
      component: AnalyticsSustainability,
      meta: {
        authRequired: true,
        guestRequired: false
      }
    },
    {
      path: '/analytics/explore',
      name: 'analytics_explore',
      component: AnalyticsExplore,
      meta: {
        authRequired: true,
        guestRequired: false
      }
    },

    {
      path: '/analytics/achievements',
      name: 'analytics_achievements',
      component: AnalyticsAchievements,
      meta: {
        authRequired: true,
        guestRequired: false
      }
    },

    {
      path: '/engagement/communication',
      name: 'engagement_communication',
      component: Communication,
      meta: {
        authRequired: true,
        guestRequired: false
      }
    },
    {
      path: '/engagement/surveys',
      name: 'engagement_surveys',
      component: Surveys,
      meta: {
        authRequired: true,
        guestRequired: false
      }
    },
    {
      path: '/engagement/survey/:id',
      name: 'engagement_survey',
      component: Survey,
      props: true,
      meta: {
        authRequired: true,
        guestRequired: false
      }
    },
    {
      path: '/engagement/leaderboard',
      name: 'engagement_leaderboard',
      component: Leaderboard,
      meta: {
        authRequired: true,
        guestRequired: false
      }
    },

    {
      path: '/mobility/carpooling',
      name: 'mobility_carpooling',
      component: Carpooling,
      meta: {
        authRequired: true,
        guestRequired: false
      }
    },

    {
      path: '/mobility/shuttles',
      name: 'mobility_shuttles',
      component: ShuttleBusList,
      meta: {
        authRequired: true,
        guestRequired: false
      }
    },

    {
      path: '/more/candidate',
      name: 'more_candidate',
      component: CandidateValidator,
      meta: {
        authRequired: true,
        guestRequired: false
      }
    },
    {
      path: '/more/kb',
      name: 'more_kb',
      component: KnowledgeBase,
      meta: {
        authRequired: true,
        guestRequired: false
      }
    },
    {
      path: '/more/employees',
      name: 'more_employees',
      component: Employees,
      meta: {
        authRequired: true,
        guestRequired: false
      }
    },

    {
      path: '/more/employee_groups',
      name: 'more_employee_groups',
      component: EmployeeGroups,
      meta: {
        authRequired: true,
        guestRequired: false
      }
    },

    {
      path: '/more/employee_group/:id',
      name: 'more_employee_group',
      component: EmployeeGroup,
      props: true,
      meta: {
        authRequired: true,
        guestRequired: false
      }
    },

    {
      path: '/settings',
      name: 'settings',
      component: Settings,
      redirect: '/settings/admins',
      children: [
        {
          path: 'admins',
          name: 'settings_admins',
          component: SettingsAdmins,
          meta: {
            authRequired: true,
            guestRequired: false
          }
        },
        {
          path: 'billing',
          name: 'settings_billing',
          component: SettingsBilling,
          meta: {
            authRequired: true,
            guestRequired: false
          }
        },
        {
          path: 'rewards',
          name: 'settings_rewards',
          component: SettingsRewards,
          meta: {
            authRequired: true,
            guestRequired: false
          }
        },
        {
          path: 'users',
          name: 'settings_users',
          component: SettingsUsers,
          meta: {
            authRequired: true,
            guestRequired: false
          }
        },
        {
          path: 'goals',
          name: 'settings_goals',
          component: SettingsGoals,
          meta: {
            authRequired: true,
            guestRequired: false
          }
        },
        {
          path: 'information',
          name: 'settings_information',
          component: SettingsInformation,
          meta: {
            authRequired: true,
            guestRequired: false
          }
        },
        {
          path: 'config',
          name: 'settings_config',
          component: SettingsConfig,
          meta: {
            authRequired: true,
            guestRequired: false
          }
        }
      ],
      meta: {
        authRequired: true,
        guestRequired: false
      }
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/User/Login.vue'),
      meta: {
        authRequired: false,
        guestRequired: true
      }
    },
    { path: '*', redirect: '/' }
  ]
});

/**
 * Handle user redirections
 */
router.beforeEach(async (to, from, next) => {
  const user = await store.dispatch('checkUserStatus');

  if (to.meta && to.meta.authRequired && isNil(user)) {
    return next(`/login?redirectUrl=${to.path}`);
  }

  if (to.meta && to.meta.guestRequired && !isNil(user)) {
    return next(`/`);
  }

  next();
});

export default router;
