export default {
  chart_happiness_by_gender_age: {
    series: [
      {
        name: 'Male',
        data: [2, 55, 57, 56, 61, 58, 3]
      },
      {
        name: 'Female',
        data: [1, 44, 65, 49, 63, 49, 6]
      }
    ],
    options: {
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        }
      },
      chart: {
        toolbar: {
          show: false
        }
      },
      dataLabels: {
        enabled: false
      },

      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },

      xaxis: {
        show: true,
        categories: ['0-18', '19-25', '26-35', '36-45', '46-55', '56-65', '66-100']
      },
      yaxis: {
        show: true
      },
      fill: {
        opacity: 1
      }
    }
  },

  chart_explore_transport_modes: {
    options: {
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        }
      },
      chart: {
        toolbar: {
          show: false
        }
      },
      dataLabels: {
        enabled: false
      },

      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },

      xaxis: {
        show: true,
        categories: ['Drive', 'Walk', 'Cycle', 'P. Transport', 'Carpool', 'Shuttle']
      },
      yaxis: {
        show: true
      },
      fill: {
        opacity: 1
      }
    },
    series: [
      {
        name: 'Number of employees',
        data: [330, 87, 295, 950, 174, 0]
      }
    ]
  },

  chart_explore_gender_age: {
    series: [
      {
        name: 'Male',
        data: [2, 55, 57, 56, 61, 58, 3]
      },
      {
        name: 'Female',
        data: [1, 44, 65, 49, 63, 49, 6]
      }
    ],
    options: {
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        }
      },
      chart: {
        toolbar: {
          show: false
        }
      },
      dataLabels: {
        enabled: false
      },

      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },

      xaxis: {
        show: true,
        categories: ['0-18', '19-25', '26-35', '36-45', '46-55', '56-65', '66-100']
      },
      yaxis: {
        show: true
      },
      fill: {
        opacity: 1
      }
    }
  },

  chart_sustainability_transport_modes: {
    series: [330, 87, 295, 950, 174],
    options: {
      labels: ['Drive', 'Walk', 'Cycle', 'P. Transport', 'Carpool'],
      legend: {
        position: 'bottom'
      }
    }
  },

  chart_achievements_co2_month: {
    series: [
      {
        name: 'This year',
        data: [34, 37, 23, 43, 36, 29, 33, 29, 37, 45, 0, 0]
      },
      {
        name: 'Last year',
        data: [91, 84, 89, 92, 95, 89, 99, 105, 98, 101, 108, 111]
      }
    ],
    options: {
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        }
      },
      chart: {
        toolbar: {
          show: false
        }
      },
      dataLabels: {
        enabled: false
      },

      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },

      xaxis: {
        show: true,
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
      },
      yaxis: {
        show: true
      },
      fill: {
        opacity: 1
      }
    }
  },

  chart_achievements_sustainable_commuters: {
    series: [
      {
        name: 'Drive',
        type: 'area',
        data: [602, 517, 498, 457, 412, 312, 344, 387, 343, 331, null, null]
      }
      //   {
      //     name: 'Happiness',
      //     type: 'line',
      //     data: [1, 2, 1.5, 1, 4, 1, 1, 5, 1, 1, null, null]
      //   }
    ],
    options: {
      dataLabels: {
        enabled: false
      },
      annotations: {
        yaxis: [
          {
            y: 150,
            borderColor: '#000',
            label: {
              show: true,
              text: '150 drivers goal',
              style: {
                color: '#fff',
                background: '#00E396'
              }
            }
          }
        ],
        xaxis: [
          {
            x: 5,
            x2: 6,
            borderColor: 'red',
            opacity: 0.5,
            label: {
              borderColor: 'red',
              style: {
                fontSize: '10px',
                color: '#fff',
                background: '#FF4560'
              },
              offsetY: -10,
              text: 'Mobility Month'
            }
          },
          {
            x: 8,
            x2: 8.25,
            borderColor: 'red',
            opacity: 0.5,
            label: {
              borderColor: 'red',
              style: {
                fontSize: '10px',
                color: '#fff',
                background: '#FF4560'
              },
              offsetY: -10,
              text: 'Healthy Week'
            }
          }
        ],
        points: [
          {
            x: 3,
            y: 500,
            marker: {
              size: 8,
              fillColor: '#fff',
              strokeColor: 'red',
              radius: 2,
              cssClass: 'apexcharts-custom-class'
            },
            label: {
              borderColor: '#FF4560',
              offsetY: 0,
              style: {
                color: '#fff',
                background: '#FF4560'
              },

              text: 'New program'
            }
          }
        ]
      },

      chart: {
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        },
        animations: {
          enabled: true
        }
      },

      stroke: {
        // curve: 'straight'
      },

      legend: {
        position: 'bottom'
      },

      xaxis: {
        show: true,
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
      },

      colors: ['#008FFB', '#FEBC3B'],

      yaxis: [
        {
          min: 0,
          axisTicks: {
            show: true
          },
          axisBorder: {
            show: true,
            color: '#008FFB'
          },
          labels: {
            style: {
              color: '#008FFB'
            }
          },
          title: {
            text: 'Drivers',
            style: {
              color: '#008FFB'
            }
          },
          tooltip: {
            enabled: true
          }
        }

        // {
        //   seriesName: 'Happiness',
        //   min: 0,
        //   max: 5,
        //   opposite: true,

        //   axisBorder: {
        //     show: true,
        //     color: '#FEB019'
        //   },
        //   labels: {
        //     style: {
        //       color: '#FEB019'
        //     }
        //   },
        //   title: {
        //     text: 'Happiness Level',
        //     style: {
        //       color: '#FEB019'
        //     }
        //   }
        // }
      ]
    }
  }
};
