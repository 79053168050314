<template>
  <div class="container">
    <page-title title="Knowledge Base" subtitle="More > Knowledge Base"></page-title>
  </div>
</template>

<script>
import PageTitle from '@/components/PageTitle'

export default {
  components: { PageTitle },
  data() {
    return {}
  }
}
</script>