<template>
  <div>
    <router-tabs :tabs="tabs"></router-tabs>

    <div class="container">
      <transition name="slide-fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
import RouterTabs from '@/components/RouterTabs'

export default {
  components: { RouterTabs },
  data() {
    return {
      tabs: [
        {
          label: 'Admins',
          path: '/settings/admins',
          active: false,
          icon: 'lock'
        },
        {
          label: 'Goals',
          path: '/settings/goals',
          active: false,
          icon: 'trophy'
        },
        {
          label: 'Information',
          path: '/settings/information',
          active: false,
          icon: 'file-alt'
        },
        {
          label: 'Users',
          path: '/settings/users',
          active: false,
          icon: 'user-friends'
        },
        {
          label: 'Rewards',
          path: '/settings/rewards',
          active: false,
          icon: 'award'
        },
        {
          label: 'Billing',
          path: '/settings/billing',
          active: false,
          icon: 'wallet'
        },
        {
          label: 'Config',
          path: '/settings/config',
          active: false,
          icon: 'cog'
        }
      ]
    }
  }
}
</script>