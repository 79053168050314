<template>
  <div>
    <page-title title="Users Settings"></page-title>

    <el-row :gutter="20">
      <el-col :sm="12">
        <card v-loading="user_add_loading" title="Add User">
          <el-form label-position="top" :model="add_user">
            <el-form-item label="Name">
              <el-input placeholder="Name" v-model="add_user.name"></el-input>
            </el-form-item>

            <el-form-item label="Email">
              <el-input placeholder="Email" v-model="add_user.email"></el-input>
            </el-form-item>

            <el-form-item label="Address">
              <el-input placeholder="Address" v-model="add_user.address"></el-input>
            </el-form-item>
          </el-form>

          <el-button
        @click="onUserAdd"
        class="secondary-color"
        :type="'primary'"
      >Add user</el-button>
        </card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import PageTitle from '@/components/PageTitle';
import Card from '@/components/Card';


export default {
  components: { PageTitle, Card },
  data() {
    return {
      add_user: {

      },
      user_add_loading: false
    }
  },
  
  methods: {
    onUserAdd() {
      this.user_add_loading = true;

      setTimeout(_ => {
        this.user_add_loading = false;
        this.add_user = {};
        this.$notify({ message: "User added correctly", title: "Success", type: "success" });
      }, 1200);
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  margin-bottom: 20px;
}

.el-form-item {
  margin-bottom: 10px;
}

.el-button {
  margin-top: 10px;
}
</style>