<template>
  <div>
    <card
      :card-id="surveyId"
      :action="actionOptions"
      @clicked="onCardClick"
      :body-style="{'padding-bottom': '10px'}"
    >
      <div class="survey-card-body">

        <div class="survey-info">
          <span
            class="survey-info-title truncate hoverable"
            @click="onTitleClick"
          >{{ data.name || "No Name" }}</span>
          <span
            class="survey-info-description"
          >{{ (data.description || "").substring(0, 150) }}{{ data.description.length > 150 ? '...' : ''}}</span>
        </div>
      </div>

      <hr class="survey-action-separator" />

      <div class="survey-card-bottom-action">
        <span>{{ data.total_respondents || 0 }} respondents</span>
        <span>{{ data.status }}</span>
      </div>
    </card>
  </div>
</template>

<script>
import Card from '@/components/Card';

export default {
  components: { Card },
  props: {
    surveyId: String,
    actionOptions: {
      type: Array,
      default: () => {
        return [{ label: 'View', value: 'view', icon: 'el-icon-view' }, { label: 'Edit', value: 'edit', icon: 'el-icon-edit-outline' }, { label: 'Publish', value: 'publish', icon: 'el-icon-share' }];
      }
    },
    data: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },

  methods: {
    onCardClick(id, command) {
      this.$emit('clicked', id, command);
    },

    onTitleClick() {
      this.$router.push(`/engagement/survey/${this.surveyId}`).catch(_ => {});
    }
  },

  computed: {
    getIcon() {
      return this.data.status == 'published' ? 'el-icon-check' : 'el-icon-refresh';
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

.survey-card-body {
  display: flex;

  .survey-info {
    margin-left: 15px;
    min-width: 0;

    & > span {
      display: block;
    }
  }

  .survey-info-title {
    font-size: 20px;
    margin-right: 45px;
  }

  .survey-info-description {
    font-size: 13px;
  }
}

.survey-action-separator {
  margin: 20px -20px 8px -20px;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.survey-card-bottom-action {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
}

.survey-finished {
  background-color: $success-color;
}
</style>