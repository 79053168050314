import { cloneDeep } from 'lodash';
import localforage from 'localforage';
import firebase from '@/firebase';

const state = {
  organization: {
    data: {}
  },
  user: {
    data: {}
  },
  employeeGroups: [],
  employees: []
};

const actions = {
  retrieveEmployees: async ({ commit, state }) => {
    const employees = await localforage.getItem('employees');

    // If the last time updated was before two days ago
    if (employees && employees.updated_at > Date.now() - 2 * 24 * 60 * 60 * 1000) {
      return commit('SET_EMPLOYEES', employees.data);
    }

    firebase
      .functions('europe-west2')
      .httpsCallable('SharoAPI')({ action: 'getAllEmployeesFromOrganization', data: {} })
      .then(result => {
        if (result.error) {
          return console.error('Error getting employees');
        }

        localforage.setItem('employees', { data: result.data.employees, updated_at: Date.now() });
        commit('SET_EMPLOYEES', result.data.employees);
      });
  }
};

const mutations = {
  SET_ORGANIZATION(_state, _org) {
    _state.organization = cloneDeep(_org);
  },

  SET_USER(_state, _user) {
    _state.user = cloneDeep(_user);
  },

  SET_EMPLOYEE_GROUPS(_state, _snap) {
    _state.employeeGroups = [];

    _snap.forEach(egRef => {
      _state.employeeGroups.push({ id: egRef.id, data: egRef.data() });
    });
  },

  SET_EMPLOYEES(_state, _employees) {
    _state.employees = [..._employees];
  }
};

const getters = {
  organization: _state => {
    return _state.organization;
  },

  user: _state => {
    return _state.user;
  },

  employeeGroups: _state => {
    return _state.employeeGroups;
  },

  findEmployeeGroup: _state => id => {
    return _state.employeeGroups.find(g => g.id == id) && _state.employeeGroups.find(g => g.id == id).data;
  },

  employees: _state => {
    return _state.employees;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
