<template>
  <nav class="navbar">
    <span class="navbar-toggle" @click="loadMobile" id="js-navbar-toggle">
      <font-awesome-icon :icon="['fas', 'bars']" />
    </span>
    <a href="javascript:;" @click="$router.push('/').catch(()=>{})" class="logo">
      <img :src="icon" />
    </a>
    <ul class="main-nav" id="js-menu">
      <!-- Signed In -->
      <li class="nav-link-container" v-if="signedIn()">
        <a href="javascript:;" class="nav-links">Analytics</a>
        <ul class="submenu">
          <li>
            <a href="javascript:;" @click="$router.push('/analytics/hr').catch(()=>{})">Wellness</a>
          </li>
          <li>
            <a
              href="javascript:;"
              @click="$router.push('/analytics/sustainability').catch(()=>{})"
            >Sustainability</a>
          </li>
          <li>
            <a href="javascript:;" @click="$router.push('/analytics/explore').catch(()=>{})">Explore</a>
          </li>
          <li>
            <a
              href="javascript:;"
              @click="$router.push('/analytics/achievements').catch(()=>{})"
            >Achievements</a>
          </li>
        </ul>
      </li>
      <li class="nav-link-container" v-if="signedIn() && false">
        <a href="javascript:;" class="nav-links">Mobility</a>
        <ul class="submenu">
          <li>
            <a
              href="javascript:;"
              @click="$router.push('/mobility/carpooling').catch(()=>{})"
            >Carpooling</a>
          </li>
          <li>
            <a
              href="javascript:;"
              @click="$router.push('/mobility/shuttles').catch(()=>{})"
            >Shuttle Bus</a>
          </li>
        </ul>
      </li>
      <li class="nav-link-container" v-if="signedIn()">
        <a href="javascript:;" class="nav-links">Engagement</a>
        <ul class="submenu">
          <li>
            <a
              href="javascript:;"
              @click="$router.push('/engagement/surveys').catch(()=>{})"
            >Surveys</a>
          </li>
          <li>
            <a
              href="javascript:;"
              @click="$router.push('/engagement/communication').catch(()=>{})"
            >Communication</a>
          </li>
          <li v-if="false">
            <a
              href="javascript:;"
              @click="$router.push('/engagement/leaderboard').catch(()=>{})"
            >Leaderboard</a>
          </li>
        </ul>
      </li>
      <li class="nav-link-container" v-if="signedIn()">
        <a href="javascript:;" class="nav-links">More</a>
        <ul class="submenu">
          <li>
            <a href="javascript:;" @click="$router.push('/more/employees').catch(()=>{})">Employees</a>
          </li>
          <li>
            <a
              href="javascript:;"
              @click="$router.push('/more/employee_groups').catch(()=>{})"
            >Employee Groups</a>
          </li>
          <li v-if="false">
            <a href="javascript:;" @click="$router.push('/more/candidate').catch(()=>{})">Candidator</a>
          </li>
          <li v-if="false">
            <a href="javascript:;" @click="$router.push('/more/kb').catch(()=>{})">Knowledge Base</a>
          </li>
        </ul>
      </li>

      <li
        class="nav-link-container"
        @click="$router.push('/settings').catch(()=>{})"
        v-if="signedIn()"
      >
        <a href="javascript:;" class="nav-links">Settings</a>
      </li>

      <li class="nav-link-container" @click="logout()" v-if="signedIn()">
        <a href="javascript:;" class="nav-links">Logout</a>
      </li>

      <!-- Not Signed In -->
      <li
        class="nav-link-container"
        @click="$router.push('/login').catch(()=>{})"
        v-if="!signedIn()"
      >
        <a href="javascript:;" class="nav-links">Login</a>
      </li>
    </ul>
  </nav>
</template>

<script>
import { setTimeout } from 'timers';
export default {
  data() {
    return {};
  },

  computed: {
    icon() {
      return require('@/assets/img/logo-black.png');
    }
  },

  methods: {
    loadMobile(e) {
      document.getElementById('js-navbar-toggle').classList.toggle('active');
      document.getElementById('js-menu').classList.toggle('active');
    },

    logout() {
      this.$store.dispatch('logout').then(result => {
        this.$router.push('/login');
      });
    },

    signedIn() {
      return this.$store.getters && this.$store.getters.userAuth;
    }
  },

  mounted() {}
};
</script>