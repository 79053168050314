<template>
  <div class="container">
    <page-title :title="this.survey.name || 'New Survey'" :action-loading="loading"></page-title>

    <el-row :gutter="20">
      <el-col :xs="24" :md="24"></el-col>
    </el-row>
  </div>
</template>

<script>
import PageTitle from '@/components/PageTitle';
import firebase from '@/firebase';

export default {
  props: {
    id: { type: String }
  },
  components: {
    PageTitle
  },

  data() {
    return {
      loading: false,
      survey: {
        name: ''
      }
    };
  },

  methods: {},

  mounted() {
    if (!this.id) {
      console.log('no survey id');
      return;
    }

    firebase
      .firestore()
      .doc(`EngagementSurvey/${this.id}`)
      .get()
      .then(snap => {
        if (!snap.exists) {
          this.$router.push('/engagement/surveys').catch(_ => {});
        }
        this.survey = snap.data();
      });
  }
};
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

.el-col {
  margin-bottom: 20px;
}
</style>
