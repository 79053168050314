<template>
  <div>
    <page-title
      title="Rewards"
      subtitle="Set rewards employees can trade for points"
      action-name="Add new"
      :action-loading="loading"
      @click="onNewReward"
    ></page-title>
  </div>
</template>

<script>
import PageTitle from '@/components/PageTitle';

export default {
  components: { PageTitle },
  data() {
    return {
      loading: false
    }
  },


  methods: {
    onNewReward() {
      console.log('new reward');
    }
  }
}
</script>

<style lang="scss" scoped>

</style>