<template>
  <div class="analytics-overview-container" :style="{'max-height': maxHeight}">
    <el-row>
      <el-col :span="12">
        <div class="analytics-icon-container" :style="style">
          <font-awesome-icon :style="iconStyle" class="analytics-icon" :icon="icon" />
        </div>
      </el-col>

      <el-col :span="12" class="analytics-info">
        <div>
          <span class="analytics-title">{{ value }}</span>
          <span class="analytics-text">{{ text }}</span>
        </div>
      </el-col>

      <!-- Progress bar -->
      <el-col :span="24" v-if="progressPercentage" class="analytics-progress-container">
        <el-progress
          :stroke-width="8"
          :percentage="progressPercentage"
          :color="progressColor || color"
          :show-text="false"
        ></el-progress>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  props: ['icon', 'color', 'value', 'text', 'iconColor', 'progressPercentage', 'progressColor', 'maxHeight'],
  data() {
    return {
      style: {},
      iconStyle: {}
    };
  },

  mounted() {
    this.style = { 'background-color': this.color };
    this.iconStyle = this.iconColor ? { color: this.iconColor } : {};
  }
};
</script>

<style lang="scss" scoped>
.analytics-overview-container {
  // max-height: 72px;
}
.analytics-icon-container {
  display: block;
  height: 70px;
  width: 70px;
  border-radius: 50%;
  background-color: rgba(128, 128, 128, 0.5);
  position: relative;

  .analytics-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 40px;
    width: 40px;
    margin-top: -20px;
    margin-left: -20px;
    color: #444;
  }

  .analytics-icon.white {
    color: white;
  }
}

.analytics-progress-container {
  padding-top: 15px;
}

.analytics-title {
  display: block;
  font-size: 26px;
  font-weight: 400;
}

.analytics-text {
  margin-top: -5px;
  display: block;
  font-size: 12px;
  color: #666;
}

.analytics-info {
  justify-content: flex-end;
  display: flex;
  align-items: center;
  height: 70px;
}

.analytics-info div {
  text-align: right;
}
</style>