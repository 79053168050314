<template>
  <div class="container">
    <page-title :title="group.name.trim() || 'New Employee Group'"></page-title>
    <card>
      <el-form label-position="top" :model="group">
        <el-row :gutter="20">
          <el-col :sm="8">
            <el-form-item label="Group Name">
              <el-input placeholder="Group Name" v-model="group.name"></el-input>
            </el-form-item>
          </el-col>

          <el-col :sm="16">
            <el-form-item label="Commuting Office">
              <el-select class="w100" v-model="group.query.commuting_offices" placeholder="Offices" multiple>
                <el-option
                  v-for="(office, key) in $store.getters.organization.data.locations"
                  :key="key"
                  :label="office.name"
                  :value="key"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :sm="6">
            <el-form-item label="Min Commuting Distance">
              <el-input-number
                class="w100"
                v-model="group.query.commute_distance.min"
                placeholder="Min Distance"
                :step="2"
                :min="0"
              ></el-input-number>
            </el-form-item>
          </el-col>

          <el-col :sm="6">
            <el-form-item label="Max Commuting Distance">
              <el-input-number
                class="w100"
                v-model="group.query.commute_distance.max"
                placeholder="Max Distance"
                :step="2"
                :min="0"
              ></el-input-number>
            </el-form-item>
          </el-col>

          <el-col :sm="6">
            <el-form-item label="Min Commuting Time">
              <el-input-number
                class="w100"
                v-model="group.query.commute_time.min"
                placeholder="Min Time"
                :step="2"
                :min="0"
              ></el-input-number>
            </el-form-item>
          </el-col>

          <el-col :sm="6">
            <el-form-item label="Max Commuting Time">
              <el-input-number
                class="w100"
                v-model="group.query.commute_time.max"
                placeholder="Max Time"
                :step="2"
                :min="0"
              ></el-input-number>
            </el-form-item>
          </el-col>

          <el-col :sm="12">
            <el-form-item label="Commuting Modes">
              <el-select class="w100" v-model="group.query.commuting_modes" placeholder="Commuting Modes" multiple>
                <el-option label="Cycle" value="cycle"></el-option>
                <el-option label="Walk" value="walk"></el-option>
                <el-option label="Telecommute" value="telecommute"></el-option>
                <el-option label="Drive Alone" value="drive"></el-option>
                <el-option label="Carpool" value="carpool"></el-option>
                <el-option label="Shuttle" value="shuttle"></el-option>
                <el-option label="Public Transport" value="public_transport"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :sm="6">
            <el-form-item label="Min Age">
              <el-input-number
                class="w100"
                v-model="group.query.age.min"
                placeholder="Min Age"
                :step="2"
                :min="0"
              ></el-input-number>
            </el-form-item>
          </el-col>

          <el-col :sm="6">
            <el-form-item label="Max Age">
              <el-input-number
                class="w100"
                v-model="group.query.age.max"
                placeholder="Max Age"
                :step="2"
                :min="0"
              ></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>

        <el-divider></el-divider>

        <el-row :gutter="20">
          <el-col :sm="9">
            <el-form-item label="Gender">
              <el-select class="w100" v-model="group.query.gender" placeholder="Gender" multiple>
                <el-option label="Male" value="male"></el-option>
                <el-option label="Female" value="female"></el-option>
                <el-option label="Other" value="other"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :sm="15">
            <el-form-item label="Sustainability Rating">
              <el-select
                class="w100"
                v-model="group.query.sustainability_rating"
                placeholder="Sustainability Rating"
                multiple
              >
                <el-option label="Superb" :value="5"></el-option>
                <el-option label="Great" :value="4"></el-option>
                <el-option label="Okay" :value="3"></el-option>
                <el-option label="Not Great" :value="2"></el-option>
                <el-option label="Zero" :value="1"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :sm="6">
            <el-form-item label="Always Update">
              <el-select class="w100" v-model="group.always_update" placeholder="Always Update">
                <el-option label="Yes" :value="true"></el-option>
                <el-option label="No" :value="false"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :sm="18">
            <el-form-item label="Happiness Level">
              <el-select
                class="w100"
                v-model="group.query.happiness_level"
                placeholder="Happiness Level"
                multiple
              >
                <el-option label="Very Happy" :value="5"></el-option>
                <el-option label="Happy" :value="4"></el-option>
                <el-option label="Okay" :value="3"></el-option>
                <el-option label="Unhappy" :value="2"></el-option>
                <el-option label="Miserable" :value="1"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <div class="group-action align-right w100">
          <el-button
            class="secondary-color"
            type="primary"
            :loading="loading"
            @click="updateGroup"
          >Save Group</el-button>
        </div>
      </el-form>
    </card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PageTitle from '@/components/PageTitle';
import Card from '@/components/Card';
import firebase from '@/firebase';
import lodash from 'lodash';

export default {
  components: { PageTitle, Card },
  props: {
    id: { type: String }
  },
  data() {
    return {
      title: 'Employee Group',
      group: {
        name: '',
        query: {
          age: { min: 0, max: 100 },
          commute_distance: { min: 0, max: 100 },
          commute_time: { min: 0, max: 120 },
          commuting_modes: [],
          commuting_offices: [],
          gender: [],
          happiness_level: [],
          sustainability_rating: []
        }
      },
      loading: false
    };
  },

  mounted() {
    if (!this.id) {
      this.$router.push('/more/employee_groups').catch(_ => {});
      return;
    } else if (this.id == 'new') {
      return;
    }

    let group = this.employeeGroups.find(g => g.id == this.id);
    this.group = lodash.cloneDeep((group && group.data) || this.group);
  },

  methods: {
    updateGroup() {
      this.loading = true;

      // Send API request
      this.SharoAPI({ action: 'createOrUpdateEmployeeGroup', data: this.document_to_upload() }).then(result => {
        if (result.data.success) {
          this.$notify({ type: 'success', message: result.data.message });
          this.$router.push('/more/employee_groups').catch(_ => {});
        } else {
          this.$notify({ type: 'error', message: result.data.error_message });
        }
      }).finally(_ => {
        this.loading = false;
      });
    },


    document_to_upload() {
      if (this.id == 'new') {
        return { ...lodash.omit(this.group, ['created_at']), organization: this.$store.getters.organization.id };
      } else {
        return { ...lodash.omit(this.group, ['created_at']), _id: this.id };
      }
    }
  },

  computed: {
    ...mapGetters(['employeeGroups'])
  },

  watch: {
    employeeGroups(groups) {
      let _group = groups.find(g => g.id == this.id);

      // If the group isn't found, go back
      if (!_group && this.id != 'new') {
        this.$router.push('/more/employee_groups').catch(_ => {});
        return;
      }

      this.group = lodash.cloneDeep((_group && _group.data) || this.group);
    }
  }
};
</script>


<style lang="scss" scoped>
.group-action {
  margin-top: 15px;
}
</style>