<template>
  <div class="container">
    <page-title title="Achievements" subtitle="Track the progress towards your goals"></page-title>

    <el-row :gutter="20">
      <el-col :xs="24" :sm="8">
        <card>
          <analytics-overview
            color="#A3D0EB"
            text="sustainable commuters"
            value="85%"
            :icon="['fas', 'bus-alt']"
            :progress-percentage="85"
          ></analytics-overview>
        </card>
      </el-col>

      <el-col :xs="24" :sm="8">
        <card>
          <analytics-overview
            color="#A3D0EB"
            text="CO2 used"
            value="346T"
            :icon="['fas', 'fire']"
            :progress-percentage="57"
          ></analytics-overview>
        </card>
      </el-col>

      <el-col :xs="24" :sm="8">
        <card>
          <analytics-overview
            color="#A3D0EB"
            text="happiness score"
            value="4.2"
            :icon="['far', 'smile']"
            :progress-percentage="84"
          ></analytics-overview>
        </card>
      </el-col>

      <!-- CO2 by month -->
      <el-col :sm="24">
        <card
          title="CO2 tons by month"
          :title-style="{ 'margin-bottom': '0px'}"
          :body-style="{'padding-bottom': '5px'}"
        >
          <div id="achievements_co2_month_container">
            <apexchart
              width="100%"
              height="260px"
              type="bar"
              :options="graphData('chart_achievements_co2_month').options"
              :series="graphData('chart_achievements_co2_month').series"
            ></apexchart>
          </div>
        </card>
      </el-col>

      <!-- Sustainable Commuters -->
      <el-col :sm="24">
        <card
          title="Non-sustainable commuters"
          :title-style="{ 'margin-bottom': '0px'}"
          :body-style="{'padding-bottom': '5px'}"
        >
          <div id="achievements_sustainable_commuters_container">
            <apexchart
              width="100%"
              height="400px"
              type="area"
              :options="graphData('chart_achievements_sustainable_commuters').options"
              :series="graphData('chart_achievements_sustainable_commuters').series"
            ></apexchart>
          </div>
        </card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import graph_data from '@/data/graph_data';
import PageTitle from '@/components/PageTitle';
import Card from '@/components/Card';
import AnalyticsOverview from '@/components/AnalyticsOverview';

export default {
  components: {
    PageTitle,
    AnalyticsOverview,
    Card
  },

  data() {
    return {
      progressColors: [{ color: '#009048', percentage: 100 }, { color: '#d80018', percentage: 50 }]
    };
  },

  mounted() {},

  methods: {
    graphData(type) {
      return graph_data[type];
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

.card {
  margin-bottom: 20px;
}
</style>
