import Vue from 'vue'

import 'pwacompat'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/styles/custom.scss'
import '@/assets/styles/animations.scss'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import VueApexCharts from 'vue-apexcharts'
import mixin_methods from '@/utils/mixin_methods'

import Card from '@/components/Card'
import listeners from '@/firebase/listeners'
import firebase from '@/firebase'
import store from './store'
import '@/misc/register-service-worker';
import router from './router'
import App from './App.vue'

Vue.use(VueApexCharts)
Vue.use(Card)

Vue.component('apexchart', VueApexCharts)

Vue.config.productionTip = false
library.add(fas)
library.add(far)
library.add(fab)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(ElementUI)

Vue.mixin({
  methods: {
    ...mixin_methods,
    SharoAPI: firebase.functions('europe-west2').httpsCallable('SharoAPI')
  }
})

new Vue({
  router,
  store,
  firebase,
  created: () => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        listeners.user()
        listeners.organization()
        listeners.engagementGroups()
        store.dispatch('checkUserClaims')
        store.dispatch('retrieveEmployees')
      } else {
        listeners.stop_listeners()
      }
    })
  },
  render: h => h(App)
}).$mount('#app')
