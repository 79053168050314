<template>
  <div>
    <page-title title="Info Settings"></page-title>

    <el-row :gutter="20">
      <el-col :sm="12">
        <card title="General Information">
          <el-form label-position="top" :model="general_information">
            <el-form-item label="Car Parks">
              <el-input placeholder="Number of car parks" v-model="general_information.number_car_parks"></el-input>
            </el-form-item>

            <el-form-item label="Employees">
              <el-input placeholder="Number of employees" v-model="general_information.number_employees"></el-input>
            </el-form-item>
          </el-form>
        </card>
      </el-col>

      <el-col :sm="12">
        <card title="Locations">
          No locations set yet
        </card>
      </el-col>
    </el-row>

    
  </div>
</template>

<script>
import PageTitle from '@/components/PageTitle';
import Card from '@/components/Card';

export default {
  components: { PageTitle, Card },

  data() {
    return {
      general_information: {

      }
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  margin-bottom: 20px;
}

.el-form-item {
  margin-bottom: 10px;
}
</style>