<template>
  <div class="container">
    <page-title title="Explore Analytics" subtitle="Explore analytics from all employees"></page-title>

    <el-row :gutter="20">
      <el-col :xs="24" :sm="8">
        <card
          title="Filters"
          @clicked="onFilterActionClicked"
          :action="[{ value: 'load', icon: 'el-icon-upload2', label: 'Load group' }]"
        >
          <el-form label-position="top" :model="employeeGroup">
            <!-- Commuting Office -->
            <el-form-item label="Commuting Office">
              <el-select
                class="w100"
                v-model="employeeGroup.data.offices"
                placeholder="Offices"
                @change="onFilterInputChanged()"
                multiple
              >
                <el-option
                  v-for="(office, key) in $store.getters.organization.data.locations"
                  :key="key"
                  :label="office.name"
                  :value="key"
                ></el-option>
              </el-select>
            </el-form-item>

            <!-- Commuting Modes -->
            <el-form-item label="Commuting Modes">
              <el-select
                class="w100"
                v-model="employeeGroup.data.modes"
                placeholder="Commuting Modes"
                @change="onFilterInputChanged()"
                multiple
              >
                <el-option label="Cycle" value="cycle"></el-option>
                <el-option label="Walk" value="walk"></el-option>
                <el-option label="Telecommute" value="telecommute"></el-option>
                <el-option label="Drive Alone" value="drive"></el-option>
                <el-option label="Carpool" value="carpooling"></el-option>
                <el-option label="Shuttle" value="shuttle"></el-option>
                <el-option label="Public Transport" value="public_transport"></el-option>
              </el-select>
            </el-form-item>

            <!-- Gender -->
            <el-form-item label="Gender">
              <el-select
                class="w100"
                v-model="employeeGroup.data.gender"
                placeholder="Gender"
                @change="onFilterInputChanged()"
                multiple
              >
                <el-option label="Male" value="male"></el-option>
                <el-option label="Female" value="female"></el-option>
                <el-option label="Other" value="other"></el-option>
              </el-select>
            </el-form-item>

            <!-- Sustainability Rating -->
            <el-form-item label="Sustainability Rating">
              <el-select
                class="w100"
                v-model="employeeGroup.data.sustainability_rating"
                placeholder="Sustainability Rating"
                @change="onFilterInputChanged()"
                multiple
              >
                <el-option label="Superb" :value="10"></el-option>
                <el-option label="Great" :value="8"></el-option>
                <el-option label="Okay" :value="6"></el-option>
                <el-option label="Not Great" :value="4"></el-option>
                <el-option label="Zero" :value="2"></el-option>
              </el-select>
            </el-form-item>

            <!-- Happiness Level -->
            <el-form-item label="Happiness Level">
              <el-select
                class="w100"
                v-model="employeeGroup.data.happiness_level"
                placeholder="Happiness Level"
                @change="onFilterInputChanged()"
                multiple
              >
                <el-option label="Very Happy" :value="10"></el-option>
                <el-option label="Happy" :value="8"></el-option>
                <el-option label="Okay" :value="6"></el-option>
                <el-option label="Unhappy" :value="4"></el-option>
                <el-option label="Miserable" :value="2"></el-option>
              </el-select>
            </el-form-item>

            <!-- Min Commuting Distance -->
            <el-form-item label="Min Commuting Distance">
              <el-input-number
                class="w100"
                v-model="employeeGroup.data.min_distance"
                placeholder="Min Distance"
                :step="2"
                :min="0"
                @change="onFilterInputChanged()"
              ></el-input-number>
            </el-form-item>

            <!-- Max Commuting Distance -->
            <el-form-item label="Max Commuting Distance">
              <el-input-number
                class="w100"
                v-model="employeeGroup.data.max_distance"
                placeholder="Max Distance"
                :step="2"
                :min="0"
                @change="onFilterInputChanged()"
              ></el-input-number>
            </el-form-item>

            <!-- Min Commute Time -->
            <el-form-item label="Min Commuting Time">
              <el-input-number
                class="w100"
                v-model="employeeGroup.data.min_time"
                placeholder="Min Time"
                :step="2"
                :min="0"
                @change="onFilterInputChanged()"
              ></el-input-number>
            </el-form-item>

            <!-- Max Commute Time -->
            <el-form-item label="Max Commuting Time">
              <el-input-number
                class="w100"
                v-model="employeeGroup.data.max_time"
                placeholder="Max Time"
                :step="2"
                :min="0"
                @change="onFilterInputChanged()"
              ></el-input-number>
            </el-form-item>

            <!-- Min Age -->
            <el-form-item label="Min Age">
              <el-input-number
                class="w100"
                v-model="employeeGroup.data.min_age"
                placeholder="Min Age"
                :step="2"
                :min="0"
                @change="onFilterInputChanged()"
              ></el-input-number>
            </el-form-item>

            <!-- Max Age -->
            <el-form-item label="Max Age">
              <el-input-number
                class="w100"
                v-model="employeeGroup.data.max_age"
                placeholder="Max Age"
                :step="2"
                :min="0"
                @change="onFilterInputChanged()"
              ></el-input-number>
            </el-form-item>

            <el-divider></el-divider>

            <!-- Group Name -->
            <el-form-item label="Group Name">
              <el-input placeholder="Group Name" v-model="employeeGroup.data.name"></el-input>
            </el-form-item>

            <el-button
              id="update_group_btn"
              class="secondary-color w100"
              type="primary"
              @click="updateGroup"
              :loading="loading_save_button"
            >Save Group</el-button>
          </el-form>
        </card>
      </el-col>

      <el-col v-loading="loading_info_graphs" :xs="24" :sm="16">
        <!-- Row for infographics -->

        <el-row :gutter="20">
          <!-- Map -->
          <el-col :xs="24">
            <card>
              <div id="explore_map_container">
                <div id="explore_map" ref="explore_map"></div>
              </div>
            </card>
          </el-col>

          <!-- Mini Info cards -->
          <el-col :xs="24" :sm="8">
            <card>
              <analytics-overview
                color="#A3D0EB"
                text="number of employees"
                :value="summary_info.number_employees"
                :icon="['fas', 'user-friends']"
              ></analytics-overview>
            </card>
          </el-col>

          <el-col :xs="24" :sm="8">
            <card>
              <analytics-overview
                color="#A3D0EB"
                text="happiness level"
                :value="summary_info.happiness_level"
                :icon="['far', 'smile']"
              ></analytics-overview>
            </card>
          </el-col>

          <el-col :xs="24" :sm="8">
            <card>
              <analytics-overview
                color="#A3D0EB"
                text="CO2 / person"
                :value="summary_info.co2_person + 'T'"
                :icon="['fas', 'fire']"
              ></analytics-overview>
            </card>
          </el-col>

          <el-col :xs="24" :sm="8">
            <card>
              <analytics-overview
                color="#A3D0EB"
                text="sustainability rating"
                :value="summary_info.sustainability_rating"
                :icon="['fas', 'leaf']"
              ></analytics-overview>
            </card>
          </el-col>

          <el-col :xs="24" :sm="8">
            <card>
              <analytics-overview
                color="#A3D0EB"
                text="minutes commuting"
                :value="summary_info.commuting_time"
                :icon="['far', 'clock']"
              ></analytics-overview>
            </card>
          </el-col>

          <el-col :xs="24" :sm="8">
            <card>
              <analytics-overview
                color="#A3D0EB"
                text="commute distance"
                :value="summary_info.commuting_distance + ' km'"
                :icon="['fas', 'road']"
              ></analytics-overview>
            </card>
          </el-col>

          <!-- Gender & Age -->
          <el-col :sm="24">
            <card
              title="Gender & Age"
              :title-style="{ 'margin-bottom': '0px'}"
              :body-style="{'padding-bottom': '5px'}"
            >
              <div id="transport_mode_breakdown">
                <apexchart
                  width="100%"
                  height="260px"
                  type="bar"
                  :options="graphData('chart_explore_gender_age').options"
                  :series="[{data: summary_info.gender_age.male, name:'Male'}, {data: summary_info.gender_age.female, name:'Female'}]"
                ></apexchart>
              </div>
            </card>
          </el-col>

          <!-- Transport mode -->
          <el-col :sm="16">
            <card
              title="Transport modes"
              :title-style="{ 'margin-bottom': '0px'}"
              :body-style="{'padding-bottom': '5px'}"
            >
              <div id="transport_mode_breakdown">
                <apexchart
                  width="100%"
                  height="173px"
                  type="bar"
                  :options="graphData('chart_explore_transport_modes').options"
                  :series="[{data: summary_info.transport_modes}]"
                ></apexchart>
              </div>
            </card>
          </el-col>

          <!-- Mini info cards -->
          <el-col :sm="8">
            <el-row :gutter="20">
              <el-col :xs="24">
                <card>
                  <analytics-overview
                    color="#A3D0EB"
                    text="leave home"
                    :value="setTime(summary_info.leaving_home)"
                    :icon="['fas', 'hourglass-start']"
                  ></analytics-overview>
                </card>
              </el-col>

              <el-col :xs="24">
                <card>
                  <analytics-overview
                    color="#A3D0EB"
                    text="leave office"
                    :value="setTime(summary_info.leaving_office)"
                    :icon="['fas', 'hourglass-end']"
                  ></analytics-overview>
                </card>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import graph_data from '@/data/graph_data';
import PageTitle from '@/components/PageTitle';
import Card from '@/components/Card';
import AnalyticsOverview from '@/components/AnalyticsOverview';
import GoogleMapsInit from '@/utils/gmaps';
import moment from 'moment';
import firebase from '@/firebase';

export default {
  components: {
    PageTitle,
    AnalyticsOverview,
    Card
  },

  data() {
    return {
      loading_save_button: false,
      loading_info_graphs: false,
      typing: {
        delay: 700,
        timer: null
      },
      employeeGroup: {
        id: null,
        data: {}
      },
      map: {
        google: null,
        fullmap: null,
        heatmap: null,
        drawing: null
      },
      filtered_data: [],
      full_data: [],

      // Collected information
      summary_info: {
        number_employees: 0,
        happiness_level: 0,
        co2_person: 0,
        sustainability_rating: 0,
        commuting_time: 0,
        commuting_distance: 0,
        gender_age: {
          male: [0, 0, 0, 0, 0, 0, 0],
          female: [0, 0, 0, 0, 0, 0, 0]
        },
        transport_modes: [0, 0, 0, 0, 0],
        leaving_home: 0,
        leaving_office: 0
      }
    };
  },

  mounted() {
    let self = this;

    GoogleMapsInit().then(google => {
      self.map.google = google;
      self.map.drawing = new google.maps.drawing.DrawingManager();

      self.map.fullmap = new google.maps.Map(this.$refs.explore_map, {
        zoom: 10,
        center: { lat: 53.3198, lng: -6.4623 },
        // mapTypeId: 'satellite',
        disableDefaultUI: true
      });

      let marker = new google.maps.Marker({
        position: { lat: 53.277252, lng: -6.208203 },
        map: self.map.fullmap,
        title: 'Office'
      });

      self.map.heatmap = new google.maps.visualization.HeatmapLayer({
        data: [new google.maps.LatLng(53.3498, -6.2603)],
        map: self.map.fullmap
      });

      self.setDrawingMap(google);

      // Get data from server
      firebase
        .database()
        .ref('organization/none/employee_engagement')
        .once('value')
        .then(snap => {
          self.full_data = snap.val();
          localStorage.setItem('organization_employee_points', JSON.stringify(self.full_data));

          Object.values(self.full_data).forEach(p => {
            self.filtered_data.push(new google.maps.LatLng(p.location.lat, p.location.lng));
          });

          self.map.heatmap.setData(self.filtered_data);

          self.applyFilters();
        });
    });
  },

  methods: {
    onFilterActionClicked(id, command) {
      if (command == 'load') {
        // TODO: Open modal to choose group to load
      }
    },

    updateGroup() {
      this.loading_save_button = true;
      setTimeout(() => {
        this.loading_save_button = false;
      }, 1500);
    },

    graphData(type) {
      return graph_data[type];
    },

    onFilterInputChanged() {
      clearTimeout(this.typing.timer);
      this.typing.timer = setTimeout(() => {
        this.applyFilters();
      }, this.typing.delay);
    },

    applyFilters() {
      this.loading_info_graphs = true;
      this.filtered_data = [];
      this.resetInfo();

      // Compute the values
      for (let key in this.full_data) {
        let item = this.full_data[key];

        // Mode
        if (this.employeeGroup.data.modes.length > 0 && !this.employeeGroup.data.modes.includes(item.transport_mode)) {
          continue;
        }

        // Gender
        if (this.employeeGroup.data.gender.length > 0 && !this.employeeGroup.data.gender.includes(item.gender)) {
          continue;
        }

        // Sustainability Rating
        if (
          this.employeeGroup.data.sustainability_rating.length > 0 &&
          !this.employeeGroup.data.sustainability_rating.includes(item.sustainability_rating) &&
          !this.employeeGroup.data.sustainability_rating.includes(item.sustainability_rating + 1)
        ) {
          continue;
        }

        // Min Commuting Distance
        if (this.employeeGroup.data.min_distance && Math.round(item.travelling_distance / 1000) < this.employeeGroup.data.min_distance) {
          continue;
        }

        // Max Commuting Distance
        if (this.employeeGroup.data.max_distance && Math.round(item.travelling_distance / 1000) > this.employeeGroup.data.max_distance) {
          continue;
        }

        // Min Commuting Time
        if (this.employeeGroup.data.min_time && item.commute_time < this.employeeGroup.data.min_time) {
          continue;
        }

        // Max Commuting Time
        if (this.employeeGroup.data.max_time && item.commute_time > this.employeeGroup.data.max_time) {
          continue;
        }

        // Min Age
        if (this.employeeGroup.data.min_age && item.age < this.employeeGroup.data.min_age) {
          continue;
        }

        // Max Age
        if (this.employeeGroup.data.max_age && item.age > this.employeeGroup.data.max_age) {
          continue;
        }

        if (!this.isWithinhBounds(item)) {
          continue;
        }

        this.addInfoToFilter(item);
      }

      this.summary_info.happiness_level = Math.round(this.summary_info.happiness_level / this.summary_info.number_employees) || 0;
      this.summary_info.co2_person = Math.round((this.summary_info.co2_person / this.summary_info.number_employees) * 10) / 10.0 || 0;
      this.summary_info.sustainability_rating = Math.round(this.summary_info.sustainability_rating / this.summary_info.number_employees) || 0;
      this.summary_info.commuting_time = Math.round(this.summary_info.commuting_time / this.summary_info.number_employees) || 0;
      this.summary_info.commuting_distance = Math.round(this.summary_info.commuting_distance / this.summary_info.number_employees) || 0;
      this.summary_info.leaving_home = Math.round(this.summary_info.leaving_home / this.summary_info.number_employees) || 0;
      this.summary_info.leaving_office = Math.round(this.summary_info.leaving_office / this.summary_info.number_employees) || 0;

      this.summary_info.number_employees = this.summary_info.number_employees * 3;

      this.map.heatmap.setData(this.filtered_data);
      setTimeout(_ => {
        this.loading_info_graphs = false;
      }, 400);
    },

    resetInfo() {
      this.summary_info = {
        number_employees: 0,
        happiness_level: 0,
        co2_person: 0,
        sustainability_rating: 0,
        commuting_time: 0,
        commuting_distance: 0,
        gender_age: {
          male: [0, 0, 0, 0, 0, 0, 0],
          female: [0, 0, 0, 0, 0, 0, 0]
        },
        transport_modes: [0, 0, 0, 0, 0, 0],
        leaving_home: 0,
        leaving_office: 0
      };
    },

    addInfoToFilter(item) {
      this.filtered_data.push(new this.map.google.maps.LatLng(item.location.lat, item.location.lng));
      this.summary_info.number_employees += 1;
      this.summary_info.happiness_level += Math.round(Math.random() * 10);
      this.summary_info.co2_person +=
        (item.transport_mode == 'drive' ? (0.00025 * item.travelling_distance) / 1000 : item.transport_mode == 'public_transport' ? (0.000015 * item.travelling_distance) / 100 : 0) * 44 * 12;
      this.summary_info.sustainability_rating += item.sustainability_rating;
      this.summary_info.commuting_distance += Math.round(item.travelling_distance / 1000);
      this.summary_info.commuting_time += Math.round(item.commute_time);
      this.summary_info.leaving_home += item.time.go_leaving_time_seconds;
      this.summary_info.leaving_office += item.time.back_leaving_time_seconds;
      this.summary_info.gender_age[item.gender][item.age < 19 ? 0 : item.age < 26 ? 1 : item.age < 36 ? 2 : item.age < 46 ? 3 : item.age < 56 ? 4 : item.age < 66 ? 5 : 6] += 3;
      this.summary_info.transport_modes[item.transport_mode == 'drive' ? 0 : item.transport_mode == 'cycle' ? 2 : 3] += 3;
    },

    setTime(time) {
      return moment.utc(time * 1000).format('HH:mm');
    },

    isWithinhBounds(point) {
      // If a rectangle hasn't been created on the map
      if (Object.keys(this.employeeGroup.data).length < 1 || !this.employeeGroup.data.selected_area) {
        return true;
      }

      return (
        point.location.lat <= this.employeeGroup.data.selected_area.northEast.lat &&
        point.location.lat >= this.employeeGroup.data.selected_area.southWest.lat &&
        point.location.lng <= this.employeeGroup.data.selected_area.northEast.lng &&
        point.location.lng >= this.employeeGroup.data.selected_area.southWest.lng
      );
    },

    setDrawingMap(google) {
      this.map.drawing.setOptions({
        drawingMode: google.maps.drawing.OverlayType.RECTANGLE,
        drawingControl: true,
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_CENTER,
          drawingModes: [google.maps.drawing.OverlayType.RECTANGLE]
        },
        rectangleOptions: {
          strokeColor: '#6c6c6c',
          strokeWeight: 1.5,
          fillColor: '#926239',
          fillOpacity: 0.4,
          editable: true,
          draggable: true
        }
      });

      this.map.drawing.setMap(this.map.fullmap);
      this.map.drawing.setDrawingMode(null);

      // Listener when square has been created
      google.maps.event.addListener(this.map.drawing, 'overlaycomplete', e => {
        if (e.type !== google.maps.drawing.OverlayType.MARKER) {
          // Switch back to non-drawing mode after drawing a shape.
          this.map.drawing.setDrawingMode(null);
          // Add an event listener that selects the newly-drawn shape when the user
          // mouses down on it.
          var newShape = e.overlay;
          newShape.type = e.type;
          google.maps.event.addListener(newShape, 'click', e => {
            if (e.vertex !== undefined) {
              if (newShape.type === google.maps.drawing.OverlayType.POLYGON) {
                var path = newShape.getPaths().getAt(e.path);
                path.removeAt(e.vertex);
                if (path.length < 3) {
                  newShape.setMap(null);
                }
              }
              if (newShape.type === google.maps.drawing.OverlayType.POLYLINE) {
                var path = newShape.getPath();
                path.removeAt(e.vertex);
                if (path.length < 2) {
                  newShape.setMap(null);
                }
              }
            }
            this.setMapSelected(newShape);
          });
          this.setMapSelected(newShape);
        }
      });
    },

    setMapSelected(mapShape) {
      this.employeeGroup.data.selected_area = {
        northEast: {
          lat: mapShape.bounds.getNorthEast().lat(),
          lng: mapShape.bounds.getNorthEast().lng()
        },
        southWest: {
          lat: mapShape.bounds.getSouthWest().lat(),
          lng: mapShape.bounds.getSouthWest().lng()
        }
      };

      this.applyFilters();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

.card {
  margin-bottom: 20px;
}

.el-form-item {
  margin-bottom: 10px;
}

#explore_map_container {
  height: 350px;

  #explore_map {
    height: 100%;
  }
}

#update_group_btn {
  margin-top: 7px;
}
</style>
