<template>
  <div class="container">
    <page-title title="Employee Leaderboard"></page-title>
  </div>
</template>

<script>
import PageTitle from '@/components/PageTitle'
import Card from '@/components/Card'

export default {
  components: {
    PageTitle,
    Card
  },

  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

.el-card {
  margin-bottom: 20px;
}

.communication-action {
  width: 100%;
  margin-top: 22px;
}
</style>
