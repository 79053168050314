import { render, staticRenderFns } from "./RouterTabs.vue?vue&type=template&id=286818ed&scoped=true&"
import script from "./RouterTabs.vue?vue&type=script&lang=js&"
export * from "./RouterTabs.vue?vue&type=script&lang=js&"
import style0 from "./RouterTabs.vue?vue&type=style&index=0&id=286818ed&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "286818ed",
  null
  
)

export default component.exports