<template>
  <el-row class="page-title-container">
    <el-col :sm="actionName ? 20 : 24" class="page-title">
      <h3>{{ title }}</h3>
      <p v-if="subtitle">{{ subtitle }}</p>
    </el-col>

    <el-col v-if="actionName" :sm="4" class="page-title-action">
      <el-button
        :loading="actionLoading"
        @click="actionClicked"
        class="secondary-color"
        :type="actionType || 'primary'"
      >{{ actionName }}</el-button>
    </el-col>
  </el-row>
</template>

<script>
export default {
  props: {
    title: { type: String },
    subtitle: { type: String },
    actionType: { type: String },
    actionName: { type: String },
    actionLoading: { type: Boolean }
  },

  methods: {
    actionClicked() {
      this.$emit('click')
    }
  }
}
</script>


<style lang="scss" scoped>
@import '@/theme/variables.scss';

.page-title-container {
  width: 100%;
}

.page-title-action {
  height: 63px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

@media (max-width: $size-md) {
  .page-title-action {
    height: auto;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 15px;
  }
}
</style>