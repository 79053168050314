<template>
  <div :style="width ? { width: width } : {}">
    <card
      @clicked="clicked"
      :card-id="userId"
      :shadow="shadow"
      :body-style="{'padding': '15px'}"
      :action="action"
      :icon="icon"
    >
      <div class="user-card-body">
        <div style="height: 56px">
          <el-avatar :size="56" :src="image"></el-avatar>
        </div>
        <div class="user-card-info">
          <span class="user-card-info-name truncate">{{ data.first_name }} {{ data.last_name }}</span>
          <span v-if="!subtext" class="user-card-info-more truncate">
            <a :href="'mailto:'+data.email">{{ data.email }}</a>
          </span>
          <span v-else class="user-card-info-more truncate">{{ subtext }}</span>
        </div>
      </div>
    </card>
  </div>
</template>

<script>
import Card from '@/components/Card'

export default {
  components: { Card },
  props: {
    image: String,
    action: Array,
    width: String,
    shadow: String,
    userId: String,
    icon: Array,
    data: Object,
    subtext: String
  },
  data() {
    return {}
  },

  mounted() {},

  methods: {
    clicked(id, command) {
      return this.$emit('clicked', id, command)
    }
  }
}
</script>

<style lang="scss" scoped>
.user-card-body {
  display: flex;
  align-items: center;

  .user-card-info {
    width: 100%;
    margin-left: 15px;
    flex: 0 1 100%;
    min-width: 0;
  }

  .user-card-info-name {
    display: block;
    font-size: 20px;
    width: calc(100% - 20px);
  }

  .user-card-info-more {
    display: block;
    font-size: 13px;
    color: rgb(110, 110, 110);
    width: calc(100% - 20px);
  }
}
</style>