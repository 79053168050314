import firebase from '@/firebase'
import { cloneDeep } from 'lodash'

const state = {
  userAuth: null,
  userClaims: null
}

const actions = {
  login({ commit, state }, { email, password }) {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(userAuth => {
          commit('SET_USER_AUTH', userAuth)
          resolve(userAuth)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signOut()
        .then(() => {
          commit('LOGOUT')
          resolve()
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  checkUserStatus({ commit, state }) {
    return new Promise((resolve, reject) => {
      firebase.auth().onAuthStateChanged(userAuth => {
        if (userAuth) {
          commit('SET_USER_AUTH', userAuth)
          resolve(userAuth)
        } else {
          resolve(userAuth)
        }
      })
    })
  },

  checkUserClaims({ commit, state }, force = false) {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .currentUser.getIdTokenResult(force)
        .then(result => {
          commit('SET_USER_CLAIMS', result.claims)
          resolve(result.claims)
        })
        .catch(() => {
          reject()
        })
    })
  }
}

const mutations = {
  LOGOUT(_state, _user) {
    _state.userAuth = null
  },
  SET_USER_AUTH(_state, _user) {
    _state.userAuth = cloneDeep(_user)
  },
  SET_USER_CLAIMS(_state, _claims) {
    _state.userClaims = cloneDeep(_claims)
  }
}

const getters = {
  userAuth: state => {
    return state.userAuth
  },
  userClaims: state => {
    return state.userClaims
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
