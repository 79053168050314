import lodash from 'lodash'
import firebase from '@/firebase'
import store from '@/store'
import router from '@/router'

let already_listened = {
  organization: false,
  user: false,
  engagementGroups: false
}

export default {
  organization: async () => {
    if (!already_listened.organization) {
      already_listened.organization = true
    } else {
      return
    }

    firebase
      .firestore()
      .collection('Organization')
      .doc(
        (await firebase.auth().currentUser.getIdTokenResult()).claims
          .organization
      )
      .onSnapshot(
        snap => {
          store.commit('SET_ORGANIZATION', { id: snap.id, data: snap.data() })

          if (
            lodash.get(
              snap.data(),
              `admins.${firebase.auth().currentUser.uid}.read`
            ) !== true
          ) {
            firebase.auth().signOut()
          }
        },
        err => {
          console.error("ERROR: You don't have read permission", err)
          store.dispatch('logout').then(() => {
            router.push('/login')
          })
        }
      )
  },

  user: () => {
    if (!already_listened.user) {
      already_listened.user = true
    } else {
      return
    }

    firebase
      .firestore()
      .collection('User')
      .doc(firebase.auth().currentUser.uid)
      .onSnapshot(snap => {
        store.commit('SET_USER', { id: snap.id, data: snap.data() })
      })
  },

  engagementGroups: async () => {
    if (!already_listened.engagementGroups) {
      already_listened.engagementGroups = true
    } else {
      return
    }

    firebase
      .firestore()
      .collection('EmployeeGroup')
      .where(
        'organization',
        '==',
        (await firebase.auth().currentUser.getIdTokenResult()).claims
          .organization
      )
      .orderBy('created_at', 'desc')
      .onSnapshot(snap => {
        store.commit('SET_EMPLOYEE_GROUPS', snap)
      })
  },

  stop_listeners: () => {
    already_listened = {}
  }
}
