<template>
  <div class="container">
    <page-title
      title="Employee Groups"
      subtitle="Group your employees to learn from them"
      action-name="Create New"
      action-type="primary"
      @click="newGroup"
    ></page-title>

    <el-row v-loading="loading" :gutter="20">
      <el-col v-for="group in employeeGroups" :sm="12">
        <card
          :title="group.data.name"
          :card-id="group.id"
          @clicked="onActionClicked"
          :action="group_actions"
        >
          <span
            class="card-subinfo"
          >Created at {{ firebase_moment(group.data.created_at.seconds * 1000) }}</span>
        </card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import PageTitle from '@/components/PageTitle';
import Card from '@/components/Card';
import firebase from '@/firebase';
import { mapGetters } from 'vuex';
import * as moment from 'moment';

export default {
  components: { PageTitle, Card },
  data() {
    return {
      group_actions: [{ label: 'Edit', value: 'edit', icon: 'el-icon-edit' }, { label: 'Delete', value: 'delete', icon: 'el-icon-delete' }],
      loading: false
    };
  },

  methods: {
    newGroup() {
      this.$router.push(`/more/employee_group/new`).catch(() => {});
    },

    firebase_moment(firebase_date) {
      return moment(firebase_date).format('Do MMM YYYY');
    },

    onActionClicked(id, command) {
      if (command == 'edit') {
        this.$router.push(`/more/employee_group/${id}`).catch(_ => {});
      } else if (command == 'delete') {
        this.$confirm('This will permanently delete the group. Do you want to continue?', 'Warning', {
          confirmButtonText: 'Yes, delete',
          cancelButtonText: 'Cancel',
          type: 'warning'
        })
          .then(() => {
            this.loading = true;

            // Send API call
            this.SharoAPI({ action: 'deleteEmployeeGroup', data: { _id: id } })
              .then(result => {
                if (result.data.success) {
                  this.$notify({ type: 'success', message: result.data.message });
                } else {
                  this.$notify({ type: 'error', message: result.data.error_message });
                }
              })
              .finally(_ => {
                this.loading = false;
              });
          })
          .catch(() => {});
      }
    }
  },

  computed: {
    ...mapGetters(['employeeGroups'])
  }
};
</script>


<style lang="scss" scoped>
.card-subinfo {
  font-size: 13px;
  margin-top: -10px;
  display: block;
}

.card {
  margin-bottom: 20px;
}
</style>