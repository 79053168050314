<template>
  <div v-if="tabs.length > 0" :style="containerStyle" class="router-tab-container">
    <div
      v-for="route in tabs"
      @click="$router.push(route.path).catch(()=>{})"
      :key="route.path"
      :class="{'router-tab-item': true, 'tab-active': activeTab(route) }"
    >
      <div class="router-tab-inside">
        <div class="router-tab-inside-icon" v-if="route.icon">
          <font-awesome-icon style="padding: 1.5px 0" :icon="fullIcon(route.icon)" />&nbsp;&nbsp;
        </div>
        <div>{{ route.label }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Array,
      default: () => []
    },
    fixed: {
      type: Boolean,
      default: false
    },
    listenPath: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    containerStyle() {
      return { position: this.fixed ? 'fixed' : 'static' }
    }
  },

  methods: {
    fullIcon(icon) {
      return icon.contructor === Array ? icon : ['fas', icon]
    },

    activeTab(route) {
      return route.active || (this.listenPath && this.$route.path == route.path)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

.router-tab-container {
  padding: 0 5px;
  width: 100%;
  background-color: #38414a;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  .router-tab-item {
    font-weight: 500;
    font-size: 15px;
    padding: 10px 5px;
    margin: 10px;
    color: rgba(255, 255, 255, 0.6);
    cursor: pointer;
    flex: 0 0;

    .router-tab-inside-icon {
      display: flex;
      align-items: center;
    }
  }

  .router-tab-item.tab-active,
  .router-tab-item:hover {
    color: rgba(255, 255, 255, 1);
  }

  .router-tab-inside {
    display: flex;
    align-items: center;
  }
}
</style>