<template>
  <div class="container">
    <page-title
      title="Communication"
      subtitle="Communicate with your employees the most efficient way."
    ></page-title>

    <el-row :gutter="20">
      <el-col :xs="24">
        <card title="Send an Email">
          <el-form ref="form" label-position="left" label-width="80px">
            <!-- To -->
            <el-form-item label="To">
              <el-select
                v-model="form.to"
                placeholder="Select an employee group"
                style="width: 100%"
              >
                <el-option
                  v-for="group in employeeGroups"
                  :label="group.data.name"
                  :value="group.id"
                ></el-option>
              </el-select>
            </el-form-item>

            <!-- Subject -->
            <el-form-item label="Subject">
              <el-input placeholder="Your subject" v-model="form.subject"></el-input>
            </el-form-item>

            <el-input
              type="textarea"
              :autosize="{ minRows: 7}"
              placeholder="Body of the message"
              v-model="form.message"
            ></el-input>
          </el-form>

          <div class="communication-action align-right">
            <el-button
              class="secondary-color"
              type="primary"
              :loading="loading"
              @click="sendEmail"
            >Send Email</el-button>
          </div>
        </card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import PageTitle from '@/components/PageTitle'
import Card from '@/components/Card'
import ElForm from 'element-ui/lib/form'
import { mapGetters } from 'vuex'

export default {
  components: {
    PageTitle,
    Card,
    ElForm
  },

  data() {
    return {
      form: {
        to: '',
        subject: '',
        message: ''
      },
      loading: false
    }
  },

  methods: {
    sendEmail() {
      this.loading = true

      this.$confirm(
        'Are you sure you want to send this email? This action cannot be undone.',
        'Warning',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }
      )
        .then(() => {
          setTimeout(() => {
            this.loading = false

            this.$notify({
              type: 'success',
              message: 'Email sent'
            })

            this.form = {
              to: '',
              subject: '',
              message: ''
            }
          }, 1500)

          this.SharoAPI({ action: 'sendEmailToUser', data: {} }).then(result => {
            console.log(result);
          })
        })
        .catch(() => {
          this.loading = false
        })
    }
  },

  computed: {
    ...mapGetters(['employeeGroups'])
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

.el-card {
  margin-bottom: 20px;
}

.communication-action {
  width: 100%;
  margin-top: 22px;
}
</style>
