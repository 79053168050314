<template>
  <div class="container">
    <page-title
      title="Sustainability Analytics"
      subtitle="All your sustainability metrics in one place"
    ></page-title>

    <el-row :gutter="20">
      <el-col :xs="24" :sm="8">
        <card>
          <analytics-overview
            color="#C9DF8A"
            text="sustainability score"
            value="3.2"
            :icon="['fas', 'leaf']"
          ></analytics-overview>
        </card>
      </el-col>

      <el-col :xs="24" :sm="8">
        <card>
          <analytics-overview
            color="#C9DF8A"
            text="CO2 emissions used"
            value="4.2T"
            :icon="['fas', 'fire']"
          ></analytics-overview>
        </card>
      </el-col>

      <el-col :xs="24" :sm="8">
        <card>
          <analytics-overview
            color="#C9DF8A"
            text="CO2 emissions saved"
            value="0.9T"
            :icon="['fas', 'seedling']"
          ></analytics-overview>
        </card>
      </el-col>

      <el-col :xs="24" :sm="16">
        <card
          title="CO2 by Gender & Age"
          :title-style="{ 'margin-bottom': '0px'}"
          :body-style="{'padding-bottom': '5px'}"
        >
          <apexchart width="100%" height="260px" type="bar" :options="options" :series="series"></apexchart>
        </card>
      </el-col>

      <el-col :xs="24" :sm="8">
        <card title="Transport Modes">
          <apexchart
            width="100%"
            height="231px"
            type="pie"
            :options="graphData('chart_sustainability_transport_modes').options"
            :series="graphData('chart_sustainability_transport_modes').series"
          ></apexchart>
        </card>
      </el-col>

      <el-col :xs="24" :sm="24">
        <card title="CO2 by Location">
          <div style="height: 350px" ref="google_maps"></div>
        </card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import graph_data from '@/data/graph_data';
import PageTitle from '@/components/PageTitle';
import Card from '@/components/Card';
import AnalyticsOverview from '@/components/AnalyticsOverview';
import GoogleMapsInit from '@/utils/gmaps';

export default {
  components: {
    PageTitle,
    AnalyticsOverview,
    Card
  },

  data() {
    return {
      series: [
        {
          name: 'Male',
          data: [2, 55, 57, 56, 61, 58, 63, 60, 3]
        },
        {
          name: 'Female',
          data: [1, 44, 65, 49, 63, 49, 70, 50, 6]
        }
      ],
      options: {
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          }
        },
        chart: {
          toolbar: {
            show: false
          }
        },
        dataLabels: {
          enabled: false
        },

        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },

        xaxis: {
          show: true,
          categories: ['0-18', '19-25', '26-35', '36-45', '46-55', '56-65', '66-100']
        },
        yaxis: {
          show: true
        },
        fill: {
          opacity: 1
        }
      }
    };
  },

  methods: {
    graphData(type) {
      return graph_data[type];
    }
  },

  mounted() {
    GoogleMapsInit().then(google => {
      new google.maps.Map(this.$refs.google_maps, {
        zoom: 10,
        center: { lat: 53.3198, lng: -6.4623 },
        // mapTypeId: 'satellite',
        disableDefaultUI: true
      });
    });
  }
};
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

.card {
  margin-bottom: 20px;
}
</style>
