<template>
  <div class="container">
    <page-title title="Candidate Validator" subtitle="More > Candidate Validator"></page-title>
  </div>
</template>

<script>
import PageTitle from '@/components/PageTitle'

export default {
  components: { PageTitle },
  data() {
    return {}
  }
}
</script>