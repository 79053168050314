<template>
  <div class="container">
    <page-title
      title="Surveys"
      subtitle="Ask your employees what you need to know."
      action-name="Add New"
      :action-loading="loading"
      @click="onNewSurvey"
    ></page-title>

    <el-row :gutter="20">
      <el-col v-for="survey in surveys" :xs="24" :md="24">
        <survey-list-item :survey-id="survey.id" :data="survey.data" @clicked="onSurveyClicked"></survey-list-item>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import PageTitle from '@/components/PageTitle';
import SurveyListItem from '@/components/SurveyListItem';
import firebase from '@/firebase';

export default {
  components: {
    PageTitle,
    SurveyListItem
  },

  data() {
    return {
      loading: false,
      surveys: []
    };
  },

  methods: {
    onNewSurvey() {
      this.$prompt('What do you want to name the survey?', {
        title: 'Create Survey',
        inputPlaceholder: 'Name of the survey',
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel'
      })
        .then(({ value }) => {
          console.log(value);
        })
        .catch(() => {});
    },

    onSurveyClicked(id, action) {
      console.log(id, action);
      if (action == 'view') {
        this.$router.push(`/engagement/survey/${id}`).catch(_ => {});
      }
    }
  },

  async mounted() {
    firebase
      .firestore()
      .collection('EngagementSurvey')
      .where('organization', '==', (await this.$store.dispatch('checkUserClaims')).organization)
      .onSnapshot(query => {
        this.surveys = [];

        query.forEach(docRef => {
          this.surveys.push({ id: docRef.id, data: docRef.data() });
        });
      });
  }
};
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

.el-col {
  margin-bottom: 20px;
}
</style>
